<template>
	<div class="select-dropdown" ref="closepanel">
		<div class="multiselect-md" @click="toggle()">
			<i :class="['fa', faIcon]" v-if="faIcon != ''"></i> <input :value="buttonText" readonly ref="infoBox" style="border:0;" /> <!-- onkeypress="this.style.width = ((this.value.length + 1) * 8) + 'px';" -->
			<i :class="['fa', panelOpen ? 'fa-caret-up' : 'fa-caret-down']" v-if="caret"></i>
			<!--i :class="['fa', 'fa-close']" v-if="selected.length > 0" @click="clear"></i--> <!--  button is-link  role="button" -->
		</div>
		<transition name="slide-fade">
			<div class="select-dropdown-panel card-md arrow-box" v-show="panelOpen">
				<select-list ref="selectList" :options="options" :selected="selected" @change="onChange" :defaultSelected="defaultSelected" :multiple="multipleCheck" :selectAllCheck="selectAllCheck" :titleOption="titleOption"></select-list>
				<div class="footer-items" @click="panelOpen = false">
					<slot name="footer"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
	
	import selectList from './select-list.vue'
	export default {
		
		components: {
			selectList
		},
		data() {
			return {
				panelOpen	  : false,
				buttonText	  : this.label,
				
				selectData	  : false,
				
				multipleCheck : false,
				selectAllCheck: false,
				
				selectedTitle : []
			};
		},
		props: {
			'caret'		: Boolean,
			'options'	: Array,
			'selected'	: Array,
			'label'		: String,
			'placeholderType': String,
			'placeholder': String,
			'faIcon'	: String,
			'defaultSelected': String,
			'multiple'	: Boolean,
			'selectAll'	: Boolean,
			'titleOption': String
		},
		
		mounted() {
			let that = this
			if( typeof that.multiple != 'undefined' ) {
				that.multipleCheck = that.multiple
			} else {
				that.multipleCheck = false
			}
			
			if( typeof that.selectAll != 'undefined' ) {
				that.selectAllCheck = that.selectAll
			} else {
				that.selectAllCheck = false
			}
			
			that.options.forEach( function(item) {
				if( that.defaultSelected == 'all' && that.multipleCheck ) {
					that.selected.push(item.id);
				}
				that.selectedTitle[item.id] = item.title
			} )
			if( that.defaultSelected == 'all' && that.multipleCheck ) {
				that.onChange()
			}
			
		},
		
		methods: {
			clear() {
				let that = this
				Object.keys(that.selected).forEach(function (/* item */) {
					that.selected.pop();
				});
				this.panelOpen = !this.panelOpen;
				this.onChange()
			},
			
			toggle() {
				/* let $event = event */
				document.addEventListener('click', this.documentClick);
				this.panelOpen = !this.panelOpen;
			},
			
			documentClick(e) {
				var el = this.$refs.closepanel,
					target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						this.panelOpen = false
						document.removeEventListener('click', this.documentClick);
					}
				}
			},
			
			onChange(selectItem) {
				// let $event = event
				// this.$emit($event);
				
				let that = this
				let selectedOptions = that.selected
				if (selectedOptions.length === 0) {
					that.buttonText = that.label;
				}
				
				if ( !that.multiple ) {
					
					that.options.forEach( function(item) {
						that.selected.splice(selectedOptions.indexOf(item.id), 1);
					} )
					that.selected.push(selectItem.id);
					that.panelOpen = false
					
					that.$emit('input', selectItem.id);
				}
				
				that.$emit('filterChange', selectItem);
				
			}
		},
		
		watch: {
			
			options: {
				handler: function (val) { // , oldVal
					
					let that = this
					val.forEach( function(item) {
						if( that.defaultSelected == 'All' && that.multipleCheck ) {
							that.selected.push(item.id);
						}
						that.selectedTitle[item.id] = item.title
					} )
					if( that.defaultSelected == 'All' && that.multipleCheck ) {
						that.onChange()
					}
				},
				deep: true
			},
			
			
			selected: {
				handler: function (val) { // , oldVal function (val) {
					// console.log(val)
					let that = this, title = ''
					
					if( this.selectAllCheck && val.length == that.options.length ) {
						this.buttonText = this.$t('Alla') + this.placeholder
					} else if( this.placeholderType == 'title' ) {
						
						if( val.length == 0 ) {
							title = this.label
						} else {
							let placeholder = []
							val.forEach( function(item) {
								placeholder.push( that.selectedTitle[item] )
							} );
							placeholder = placeholder.join(',')
							title 		= placeholder
							
							var infoBox = this.$refs.infoBox
							var width   = (infoBox.clientWidth + 10)
							
							var calculSize 	= (width * title.length) / ((title.length + 1) * 8)
							var lastValue 	=  calculSize.toString()
								lastValue 	=  parseInt( lastValue )
							// ((this.value.length + 1) * 8) + 'px';
							title 		= title.length >= lastValue ? title.substring( 0, lastValue - 2 ) + "...." : title
						}
						
						this.buttonText = title;
					} else {
						let title = val.length + ' Item selected'
						if( val.length > 1 ) {
							title = val.length + ' Items selected'
						} else if( val.length == 0 ) {
							title = this.label
						}
						this.buttonText = title;
					}
				},
				deep: true
			},
		},
	}
</script>
<style lang="scss">
	.select-dropdown {
		&:hover, &:active, &:focus {
			border: 1px solid #01A3D6 !important;
		}
	}
	.select-dropdown {
		position: relative;
		padding: 5px 5px 5px 8px;
		height: 40px;
		/* margin: auto; */
		
		cursor: pointer;
		border: 1px solid #ced4da;
		border-radius: 4px;
		width: 100%;
		/* max-width: 32em; */
		
		.multiselect-md {
			background-color: #fff;
			border: 0px;
			height: 30px;
			word-break: break-word;
			width: 100%;
			display: flex;
			color: #8c8b8b;
			margin: auto;
			position: relative;
			
			>input {
				width: 75%;
				font-size: 13px;
				margin-top: auto;
				margin-bottom: auto;
				padding-left: 5px;
				padding-right: 5px;
				cursor: pointer;
			}
			
			.fa-caret-down, .fa-caret-up {
				margin-left: auto;
				margin-right: 5px;
			}
			.fa {
				margin-top: auto;
				margin-bottom: auto;
			}
			.fa-close {
				position: relative;
				right: 0px;
				top: 2px;
			}
			
			.fa-caret-down, .fa-caret-up {
				color: #a2a2a2 !important;
				position: relative;
				right: 10px;
			}
		}
		
	}
	
	.select-dropdown-panel {
		
		.option-drop {
			font-weight: 600;
			padding: 8px 8px 0px 8px !important;
			cursor: context-menu;
			font-size: 14px !important;
		}
		
		.footer-items {
			>* {
				padding: 10px 12px;
				display: block;
				color: #4e4e4e;
				font-size: 13px;
			}
			a:hover, span:hover {
				background-color: #eee;
			}
		}
	}
	.select-dropdown .select-dropdown-panel {
		width: 100%;
		position: relative;
		min-width: max-content;
		right: 8px;
		top: 6px;
		z-index: 99;
		margin-top: 4px;
		box-shadow: 0px 0px 4px #eee;
		background-color: #fff;
		border: 1px solid #eee;
	}
	
	.select-dropdown .select-list-actions {
		margin-bottom: 0px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 8px 10px 6px 10px;
		
		input[type="text"] {
			font-size: 13px;
		}
		
		.fa-search {
			position: absolute;
			right: 14px;
			top: 12px;
			color: #ddd;
			font-size: 14px;
		}
		
	}
	/* .select-dropdown .select-list-actions  */
	.button.is-link {
		color: #6c757d;
		width: 14%;
		padding-bottom: 4px;
	}
	.select-dropdown .select-list {
		padding-top: 0px;
		/* display: flex; */
		flex-wrap: wrap;
		max-height: 36vh;
		overflow-y: auto;
	}
	.select-dropdown .select-item {
		flex-basis: 20%;
		margin-bottom: 1%;
		justify-content: flex-start;
	}
	.select-dropdown .toggle-button {
	    background: none;
	    color: #676d73;
	    border: none;
	    padding: 6px 8px 5px 4px;
	    position: relative;
	    margin-left: 0 !important;
	    transition: font-weight .1s ease-in;
	    font-size: 13px;
	    display: flex;
	    align-items: center;
	    
	    b, strong {
	        font-weight: 500;
	    }
	}

	
	.select-dropdown .toggle-button:hover {
		background: #eee;
		cursor: pointer;
	}
	.select-dropdown .toggle-button:focus {
		box-shadow: none;
	}
	
	
	.select-dropdown .toggle-button.is-selected {
		color: #676d73;
	}
	.select-dropdown .toggle-button.is-selected .fa-select-toggle {
		opacity: 1;
	}
	.select-dropdown .toggle-button .fa-select-toggle {
		opacity: 0;
	}

	.select-dropdown .toggle-button span {
	    white-space: nowrap;
	    overflow: hidden !important;
	    text-overflow: ellipsis;
	    display: inline-block;
	    line-height: 1.2;
	    margin-right: 35px;
	}
	.select-dropdown .toggle-button span.badge{
		white-space: initial;
		overflow: intial !important;
		text-overflow: initial;
		display:inline-block;
		margin-right:0;
	}
	
	.select-dropdown .toggle-button .fa {
		font-size: 14px;
		padding: 0px 0px 0px 6px;
		width: 26px;
		transition: all .1s ease-in;
	}
	.select-dropdown .arrow-box:after,
	.select-dropdown .arrow-box:before {
		bottom	: 100%;
		left	: 25px;
		border	: solid transparent;
		content	: " ";
		height	: 0;
		width	: 0;
		position: absolute;
		pointer-events: none;
	}
	.select-dropdown .arrow-box:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 7px;
		margin-left: -7px;
	}
	.select-dropdown .arrow-box:before {
		border-color: rgba(234, 234, 234, 0);
		border-bottom-color: #e0e0e0;
		border-width: 8px;
		margin-left: -8px;
	}

	.slide-fade-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-leave-active {
		transition: all .1s ease;
	}

	.slide-fade-enter, .slide-fade-leave-active {
		transform: translateY(20px);
		opacity: 0;
	}
	
</style>
